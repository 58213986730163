<template>
  <b-card
    no-body
    class="custom-counter"
  >
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <div class="font-weight-bolder title">
          {{ titleText }}
        </div>
        <div class="font-weight-bold font-small-2 sub-title text-muted">
          {{ subTitleText }}
        </div>
        <div class="font-medium-4 font-weight-bolder text-dark">
          <div
            v-if="isLoading"
            class="text-left d-flex align-items-center mt-1"
          >
            <b-spinner
              variant="muted"
              small
            />
            <div
              class="font-small-4 font-weight-normal text-muted"
              style="margin-left: 5px"
            >
              Yükleniyor
            </div>
          </div>
          <template v-else>
            {{ prefixText }} {{ valueData }} {{ suffixText }}
          </template>
        </div>
      </div>
      <div>
        <div
          :class="'text-'+colorClass"
          class="icon d-flex align-items-center justify-content-center"
        >
          <FeatherIcon
            :icon="iconName"
            size="22"
          />
        </div>
      </div>
    </div>
    <div class="refresh-button position-absolute position-top-0 position-right-0">
      <b-button
        v-if="refreshData"
        v-b-tooltip.hover.top="tooltipText"
        pill
        class="btn-icon"
        size="sm"
        variant="light"
        @click="refreshData(dataIndex)"
      >
        <FeatherIcon icon="RefreshCwIcon" />
      </b-button>
    </div>
  </b-card>
</template>
<script>
import {
  BCard, BButton, BSpinner, VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'RentalCounterCard',
  components: { BCard, BButton, BSpinner },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataIndex: {
      type: String,
      required: true,
    },
    titleText: {
      type: String,
      required: true,
    },
    subTitleText: {
      type: String,
      required: true,
    },
    valueData: {
      type: Number,
      required: true,
    },
    prefixText: {
      type: String,
      default: null,
    },
    suffixText: {
      type: String,
      default: null,
    },
    colorClass: {
      type: String,
      default: null,
    },
    iconName: {
      type: String,
      default: null,
    },
    tooltipText: {
      type: String,
      default: null,
    },
    refreshData: {
      type: Function,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style>
.refresh-button{
  margin-top: -10px !important;
  margin-right: -10px !important;
}
.refresh-button .btn{
  border:1px solid #ddd
}
</style>
