<template>
  <div>
    <b-row class="mt-1">
      <b-col
        v-for="counter in dataCounters"
        :key="counter.id"
        cols="12"
        md="4"
        class="mb-1"
      >
        <rental-counter-card
          :value-data="Number(counter.count)"
          :sub-title-text="counter.subTitle"
          :title-text="counter.title"
          :icon-name="counter.icon"
          :color-class="counter.variant"
          :refresh-data="counter.dateFilter ? refreshData:null"
          :data-index="counter.id"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="modalData.status"
      title="Tarihler Arası Filtrele"
      centered
      no-close-on-backdrop
    >
      <b-form-group
        label="Başlangıç Tarihi"
        label-for="status_sdate"
      >
        <b-form-datepicker
          v-model="modalData.status_sdate"
          v-bind="{labelNoDateSelected: 'Başlangıç Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-form-group>
      <b-form-group
        label="Bitiş Tarihi"
        label-for="status_edate"
      >
        <b-form-datepicker
          v-model="modalData.status_edate"
          v-bind="{labelNoDateSelected: 'Bitiş Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-form-group>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="modalData.status = false"
        >
          <feather-icon icon="XIcon" /> İptal
        </b-button>
        <b-button
          variant="success"
          :disabled="modalData.loading"
          @click="filterData"
        >
          <b-spinner
            v-if="modalData.loading"
            small
          />
          <feather-icon
            v-if="!modalData.loading"
            icon="FilterIcon"
          /> Filtrele
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BModal, BButton, BFormDatepicker, BSpinner, BFormGroup,
} from 'bootstrap-vue'
import RentalCounterCard from '@/views/Rental/Stocks/elements/CounterCard.vue'

export default {
  name: 'Counters',
  components: {
    BFormGroup,
    BFormDatepicker,
    RentalCounterCard,
    BRow,
    BCol,
    BModal,
    BButton,
    BSpinner,
  },
  data() {
    return {
      modalData: {
        loading: false,
        status: false,
        status_sdate: null,
        status_edate: null,
        id_com_rent_stock_status: null,
      },
    }
  },
  computed: {
    dataCounters() {
      return this.$store.getters['rentalStocks/dataCounters']
    },
  },
  created() {
    this.getCounters()
  },
  methods: {
    getCounters() {
      this.$store.dispatch('rentalStocks/getDataCounters')
    },
    refreshData(idStatus) {
      console.log('aaa')
      this.modalData.loading = false
      this.modalData.status = true
      this.modalData.id_com_rent_stock_status = idStatus
      this.modalData.status_sdate = null
      this.modalData.status_edate = null
    },
    filterData() {
      this.modalData.loading = true
      this.$store.dispatch('rentalStocks/getDataCounters', {
        id_com_rent_stock_status: this.modalData.id_com_rent_stock_status,
        status_sdate: this.modalData.status_sdate,
        status_edate: this.modalData.status_edate,
      }).then(response => {
        if (response) {
          this.modalData.status = false
          this.modalData.loading = false
        }
      })
    },
  },
}
</script>
